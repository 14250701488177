var ready, tags;

import Rails from '@rails/ujs';

ready = function() {
  return tags();
};

tags = function() {
  var fetch_tags, form, image_field, tag_field;
  form = $('form[id$="app-form"]');
  if (form.length <= 0) {
    return;
  }
  image_field = form.find("select[id$='_image_id']");
  if (image_field.length !== 1) {
    return;
  }
  tag_field = form.find("select[id$='_tag_id']");
  if (tag_field.length !== 1) {
    return;
  }
  fetch_tags = function() {
    var name;
    name = image_field.find("option:selected").text();
    if (name === '') {
      return;
    }
    tag_field.prop('disabled', 'disabled');
    return Rails.ajax({
      url: `/images/${name}/tags.json`,
      type: 'GET',
      success: function(data) {
        var i, len, tag;
        console.log(data);
        tag_field.empty();
        tag_field.append("<option value=''></option>");
        for (i = 0, len = data.length; i < len; i++) {
          tag = data[i];
          tag_field.append(`<option value='${tag.id}'>${tag.name}</option>`);
        }
        return tag_field.prop('disabled', false);
      },
      error: function(xhr, resp, text) {
        console.log(xhr, resp, text);
        return tag_field.prop('disabled', false);
      }
    });
  };
  return image_field.on('change', function(event) {
    return fetch_tags();
  });
};

$(document).on('turbolinks:load', ready);
